<template>
  <div>
    <nav v-if="isLoggedIn && isInitiated" class="navbar-custom">
      <router-link :to="{ name: 'Dashboard'}" class="navbar-item">
        <span class="icon is-small">
          <i class="fas fa-chevron-left"></i>
        </span>
        <span>Passport</span>
      </router-link>
    </nav>
    <hr class="separation" />

    <div class="contentWrapper padding has-background-white">
      <h2 class="title is-3">Matches</h2>
      <p>You were matched to these people based on their needs. This means you have expertise that is valuable to them. Connect and start exchanging your social capital.</p>

      <transition name="fade" mode="out-in">
        <div
          v-if="!isFirstLoad"
          v-masonry
          transition-duration="0.2s"
          item-selector=".column"
          class="is-multiline masonry-container"
        >
          <match-item
            v-for="(match, index) in matches"
            :key="index"
            :match="match"
            class="column is-half-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-third-fullhd"
          ></match-item>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import match from '../providers/match'

export default {
  data() {
    return {
      isLoading: false,
      isFirstLoad: true,
      bottom: false,
      matches: [],
      page: 1,
      itemsPerPage: 20,
      reachedEnd: false
    }
  },

  created() {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.updateOnlineStatus)

    this.$store.commit('setSelectedPublicProfile', {
      profileId: 0,
      view: 'detail'
    })
  },

  computed: {
    ...mapGetters({
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn'
    })
  },

  watch: {
    bottom(bottom) {
      if (bottom) {
        if (!this.isLoading) {
          this.page = this.page + 1
          this.getMatchedWith()
        }
      }
    },
    isInitiated: {
      handler: 'getMatchedWith',
      immediate: true
    }
  },

  methods: {
    bottomVisible() {
      const scrollY = window.scrollY
      const visible = document.documentElement.clientHeight
      const pageHeight = document.documentElement.scrollHeight
      const bottomOfPage = visible + scrollY >= pageHeight
      return bottomOfPage || pageHeight < visible
    },

    getMatchedWith() {
      let self = this

      match.methods
        .getMatchedWith(this.page, this.itemsPerPage)
        .then(response => {
          this.processQuestionResponse(response)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          self.isLoading = false
          self.isFirstLoad = false
        })
    },

    processQuestionResponse(response) {
      if (response.data) {
        let matches = []

        if (response.data.MorePages === 0) {
          this.reachedEnd = true
        }

        if (this.page > 1) {
          matches = this.matches
        }

        for (let i = 0; i < response.data.Results.length; i++) {
          matches.push(response.data.Results[i])
        }

        this.matches = matches
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
